/* eslint-disable */
import jQuery from 'jquery';
import Swiper from 'swiper';

(($) => {
// Params
  const mainSliderSelector = '.main-slider';
  const navSliderSelector = '.nav-slider';
  const interleaveOffset = 0.5;

  const $sliderLinks = $('.nav-slider .links a');
  let maxW = 0;
  $sliderLinks.each((i, object) => {
    const width = $(object).width();
    if (width > maxW) {
      maxW = Math.floor(width) + 20;
    }
  });
  $sliderLinks.css('width', `${maxW}px`);

  // Main Slider
  const mainSliderOptions = {
    loop: true,
    slidesPerView: 1,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    loopAdditionalSlides: 100,
    grabCursor: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.slide-button-next',
      prevEl: '.slide-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      init() {
        this.autoplay.stop();
      },
      imagesReady() {
        this.el.classList.remove('loading');
        this.autoplay.start();
      },
      progress() {
        const swiper = this;
        for (let i = 0; i < swiper.slides.length; i += 1) {
          const slideProgress = swiper.slides[i].progress;
          const innerOffset = swiper.width * interleaveOffset;
          const innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector('.slide-bgimg').style.transform = `translateX(${innerTranslate}px)`;
        }
      },
      touchStart() {
        const swiper = this;
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = '';
        }
      },
      setTransition(speed) {
        const swiper = this;
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = `${speed}ms`;
          swiper.slides[i].querySelector('.slide-bgimg').style.transition = `${speed}ms`;
        }
      },
    },
  };
  const mainSlider = new Swiper(mainSliderSelector, mainSliderOptions);

  // Navigation Slider
  let activeBeforeChange = 0;
  let initialised = false;
  const  navSliderOptions = {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    loopAdditionalSlides: 10,
    speed:1000,
    centeredSlides : true,
    slideToClickedSlide: true,
    on: {
      init() {
        initialised = true;

      },

      imagesReady: function(){
        this.el.classList.remove('loading');
      },
      click: function(){
        mainSlider.autoplay.stop();
      }
    }
  };
  const navSlider = new Swiper(navSliderSelector, navSliderOptions);

  // Matching sliders
  if (document.querySelector(mainSliderSelector) && document.querySelector(navSliderSelector)) {
    mainSlider.controller.control = navSlider;
    navSlider.controller.control = mainSlider;
  }

})(jQuery);
